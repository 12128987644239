import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getPlantById(context, id) {
      const response = await Vue.axios.get(`${URL_API}plant/view/${id}`);
      return response["data"];
    },
    getPlantByExcel(context) {
      let districts = "";
      if (context.rootState.settings.ids_districts)
        districts = `district_id=${context.rootState.settings.ids_districts}&`;
      let types = "";
      if (context.rootState.settings.ids_types)
        types = `type_id=${context.rootState.settings.ids_types}&`;
      let sanitary = "";
      if (context.rootState.settings.ids_sanitary)
        sanitary = `sanitary_id=${context.rootState.settings.ids_sanitary}&`;
      let statuses = "";
      if (context.rootState.settings.ids_statuses)
        statuses = `status_id=${context.rootState.settings.ids_statuses}&`;
      let events = "";
      if (context.rootState.settings.ids_events)
        events = `event_id=${context.rootState.settings.ids_events}&`;
      let vermin = "";
      if (context.rootState.settings.ids_vermin)
        vermin = `vermin_id=${context.rootState.settings.ids_vermin}&`;
      let diseases = "";
      if (context.rootState.settings.ids_diseases)
        diseases = `vermin_id=${context.rootState.settings.ids_diseases}&`;
      Vue.axios({
        url: `${URL_API}plant/exportExcel?${districts}${types}${sanitary}${statuses}${events}${vermin}${diseases}layer_id=${context.rootState.settings.layerTarget}`,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "file.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    async createPlantData(
      context,
      {
        geometry,
        layer_id,
        age,
        height,
        diameter,
        serial_number,
        sanitary_id,
        category_id,
        status_id,
        district_id,
        in_charge,
        planting_date,
        quantity,
        length,
        area,
        vermin,
        diseases,
        events,
        type_id,
        breeds,
        lawn_name,
        lawn_type,
        bushes,
        vines,
        flower_garden_id,
        flowers
      }
    ) {
      const config = {};
      let body = {};
      if (geometry) body.geometry = geometry;
      if (layer_id) body.layer_id = layer_id;
      if (age) body.age = age;
      if (height) body.height = height;
      if (diameter) body.diameter = diameter;
      if (serial_number) body.serial_number = serial_number;
      if (sanitary_id) body.sanitary_id = sanitary_id;
      if (category_id) body.category_id = category_id;
      if (status_id) body.status_id = status_id;
      if (district_id) body.district_id = district_id;
      if (in_charge) body.in_charge = in_charge;
      if (planting_date) body.planting_date = planting_date;
      if (quantity) body.quantity = quantity;
      if (length) body.length = length;
      if (area) body.area = area;
      if (vermin) body.vermin = vermin;
      if (diseases) body.diseases = diseases;
      if (events) body.events = events;
      if (type_id) body.type_id = type_id;
      if (breeds) body.breeds = breeds;
      if (lawn_name) body.lawn_name = lawn_name;
      if (lawn_type) body.lawn_type = lawn_type;
      if (bushes) body.bushes = bushes;
      if (vines) body.vines = vines;
      if (flower_garden_id) body.flower_garden_id = flower_garden_id;
      if (flowers) body.flowers = flowers;
      try {
        await Vue.axios.post(`${URL_API}plant/store`, body, config);
        context.commit("updatePlantMsg", 1);
      } catch (error) {
        context.commit("updatePlantMsg", 0);
      }
    },
    async pushPlantData(
      context,
      {
        id,
        geometry = null,
        age,
        height,
        diameter,
        serial_number,
        sanitary_id,
        category_id,
        status_id,
        district_id,
        in_charge,
        planting_date,
        quantity,
        length,
        area,
        vermin,
        diseases,
        events,
        type_id,
        breeds,
        lawn_name,
        lawn_type,
        bushes,
        vines,
        flower_garden_id,
        flowers
      }
    ) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (age) body.age = age;
      if (height) body.height = height;
      if (diameter) body.diameter = diameter;
      if (serial_number) body.serial_number = serial_number;
      if (sanitary_id) body.sanitary_id = sanitary_id;
      if (category_id) body.category_id = category_id;
      if (status_id) body.status_id = status_id;
      if (district_id) body.district_id = district_id;
      if (in_charge) body.in_charge = in_charge;
      if (planting_date) body.planting_date = planting_date;
      if (quantity) body.quantity = quantity;
      if (length) body.length = length;
      if (area) body.area = area;
      if (vermin) body.vermin = vermin;
      if (diseases) body.diseases = diseases;
      if (events) body.events = events;
      if (type_id) body.type_id = type_id;
      if (breeds) body.breeds = breeds;
      if (lawn_name) body.lawn_name = lawn_name;
      if (lawn_type) body.lawn_type = lawn_type;
      if (bushes) body.bushes = bushes;
      if (vines) body.vines = vines;
      if (flower_garden_id) body.flower_garden_id = flower_garden_id;
      if (flowers) body.flowers = flowers;
      try {
        const response = await Vue.axios.post(
          `${URL_API}plant/update`,
          body,
          config
        );
        context.commit("updatePlantMsg", 1);
        console.dir(response);
      } catch (error) {
        context.commit("updatePlantMsg", 0);
      }
    },
    async pushPlantGeometries(context, data) {
      const response = await Vue.axios.post(
        `${URL_API}plant/updateGeometries`,
        data
      );
      console.dir(response);
      return response.data;
    },
    async destroyPlantData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}plant/destroy`, body, config);
        context.commit("updatePlantMsg", 1);
      } catch (error) {
        context.commit("updatePlantMsg", 0);
      }
    }
  },
  mutations: {
    updatePlantsData(state, plantsData) {
      state.plantsData = plantsData;
    },
    updatePlantMsg(state, data) {
      state.plantMsg = data;
    }
  },
  state: {
    plantsData: {},
    plantMsg: null
  },
  getters: {
    allPlantsData(state) {
      return state.plantsData;
    }
  }
};
