import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getTaxatorGrid(context) {
      const response = await Vue.axios.get(`${URL_API}taxatorgrid`);
      let data = response["data"];
      context.commit("updateTaxatorGrid", data);
      return data;
    },
    async createTaxatorGrid(context, { geometry, name, user_ids }) {
      const config = {};
      let body = {};
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (user_ids) body.user_ids = user_ids;

      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/store`, body, config);
        context.commit("updateTaxatorMsg", 1);
      } catch (error) {
        context.commit("updateTaxatorMsg", 0);
      }
    },
    async pushTaxatorGrid(context, { id, geometry = null, name, user_ids }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (user_ids) body.user_ids = user_ids;

      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/update`, body, config);
        context.dispatch("getTaxatorGrid");
        context.commit("updateTaxatorMsg", 1);
      } catch (error) {
        context.commit("updateTaxatorMsg", 0);
      }
    },
    async destroyTaxatorGrid(context, id) {
      const config = {};
      const body = {
        id: id
      };
      try {
        await Vue.axios.post(`${URL_API}taxatorgrid/destroy`, body, config);
        context.dispatch("getTaxatorGrid");
        context.commit("updateTaxatorMsg", 1);
      } catch (error) {
        context.commit("updateTaxatorMsg", 0);
      }
    },
    async getTaxatorGridByUser(context) {
      const user = JSON.parse(
        JSON.stringify(context.rootState.user.currentUser)
      );
      const response = await Vue.axios.get(
        `${URL_API}taxatorgrid/getbyuserid/${user.id}`
      );
      return response["data"];
    }
  },
  mutations: {
    updateTaxatorGrid(state, data) {
      state.taxatorgrid = data;
    },
    updateTaxatorMsg(state, data) {
      state.taxatorMsg = data;
    }
  },
  state: {
    taxatorgrid: {},
    taxatorMsg: null
  },
  getters: {
    allTaxatorGrid(state) {
      return state.taxatorgrid;
    }
  }
};
