export default {
  state: {
    msg: ""
  },
  mutations: {
    updateMessage(state, data) {
      state.msg = data;
    }
  }
};
