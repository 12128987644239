import Vue from "vue";
import router from "@/router";

import { URL_API } from "../../settings/apiConnect";

export default {
  state: {
    token: "",
    currentUser: {},
    userRole: null,
    msg: null
  },
  mutations: {
    async initToken(state) {
      if (localStorage.getItem("token") != null) {
        state.token = localStorage.getItem("token");
        Vue.axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${state.token}`;
        state.userRole = parseInt(localStorage.getItem("userRole"));
      }
    },

    async clearToken(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("userRole");
      state.token = "";
      Vue.axios.defaults.headers.common["Authorization"] = null;
      await router.push("/login");
    },

    updateToken(state, token) {
      localStorage.setItem("token", token);
      state.token = token;
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${state.token}`;
    },

    updateCurrentUser(state, data) {
      localStorage.setItem("userRole", data.roles[0].id);
      state.userRole = data.roles[0].id;
      state.currentUser = data;
    },

    updateMessage(state, data) {
      state.msg = data;
    }
  },
  actions: {
    async loginUser(context, { email, password }) {
      const config = {};
      const body = {
        email: email,
        password: password
      };
      try {
        const response = await Vue.axios.post(`${URL_API}login`, body, config);
        const data = response.data;
        if (data.access_token) {
          context.commit("updateToken", data.access_token);
          context.commit("updateMessage", 1);
          await router.push("/main/map");
        }
      } catch (error) {
        context.commit("updateMessage", 0);
      }
    },

    async getCurrentUser(context) {
      const config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      };
      const response = await Vue.axios.get(
        `${URL_API}user/getCurrentUser`,
        config
      );
      context.commit("updateCurrentUser", response.data);
    }
  }
};
