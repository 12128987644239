import Vue from "vue";

import { URL_API } from "../../settings/apiConnect";

export default {
  actions: {
    async getDistrictsData(context) {
      const response = await Vue.axios.get(`${URL_API}district`);
      let data = response["data"];
      context.commit("updateDistrictsData", data);
      context.commit("updateFilteredDisctricts", data);
      return data;
    },
    async getDistrictQuantity(context) {
      let districts = "";
      if (context.rootState.settings.ids_districts)
        districts = `district_id=${context.rootState.settings.ids_districts}&`;
      let types = "";
      if (context.rootState.settings.ids_types)
        types = `type_id=${context.rootState.settings.ids_types}&`;
      let sanitary = "";
      if (context.rootState.settings.ids_sanitary)
        sanitary = `sanitary_id=${context.rootState.settings.ids_sanitary}&`;
      let statuses = "";
      if (context.rootState.settings.ids_statuses)
        statuses = `status_id=${context.rootState.settings.ids_statuses}&`;
      let events = "";
      if (context.rootState.settings.ids_events)
        events = `event_id=${context.rootState.settings.ids_events}&`;
      let vermin = "";
      if (context.rootState.settings.ids_vermin)
        vermin = `vermin_id=${context.rootState.settings.ids_vermin}&`;
      let diseases = "";
      if (context.rootState.settings.ids_diseases)
        diseases = `disease_id=${context.rootState.settings.ids_diseases}&`;
      let response;
      if (context.rootState.settings.layerTarget === 0) {
        response = await Vue.axios.get(
          `${URL_API}district/getbyfilter?${districts}${types}${sanitary}${statuses}${events}${vermin}${diseases}`
        );
      } else {
        response = await Vue.axios.get(
          `${URL_API}district/getbyfilter?${districts}${types}${sanitary}${statuses}${events}${vermin}${diseases}layer_id=${context.rootState.settings.layerTarget}`
        );
      }
      let data = response["data"];
      return data;
    },
    async createDistrictData(context, name) {
      const config = {};
      const body = {
        name: name
      };
      await Vue.axios.post(`${URL_API}district/store`, body, config);
      context.dispatch("getDistrictsData");
    },
    async pushDistrictData(context, { id, geometry = null, name, color }) {
      const config = {};
      const body = { id: id };
      if (geometry) body.geometry = geometry;
      if (name) body.name = name;
      if (color) body.color = color;

      try {
        await Vue.axios.post(`${URL_API}district/update`, body, config);
        context.dispatch("getDistrictsData");
        context.commit("updateDistrictsMsg", 1);
      } catch (error) {
        context.commit("updateDistrictsMsg", 0);
      }
    },
    async destroyDistrictData(context, id) {
      const config = {};
      const body = {
        id: id
      };
      await Vue.axios.post(`${URL_API}district/destroy`, body, config);
      context.dispatch("getDistrictsData");
    },
    async getDistrictByArea(context, wkt) {
      const response = await Vue.axios.get(
        `${URL_API}district/getbyarea?geometry=` + wkt
      );
      let data = response["data"];
      return data[0];
    }
  },
  mutations: {
    updateDistrictsData(state, districtsData) {
      state.districtsData = districtsData;
    },
    updateDistrictsMsg(state, data) {
      state.districtsMsg = data;
    }
  },
  state: {
    districtsData: {},
    districtsMsg: null
  },
  getters: {
    allDistrictsData(state) {
      return state.districtsData;
    }
  }
};
