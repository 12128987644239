import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import VueLayers from "vuelayers";
import "vuelayers/dist/vuelayers.css";
import { XyzSource } from "vuelayers";
import VueExpandableImage from "vue-expandable-image";
import "elm-pep";

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueLayers);
Vue.use(XyzSource);
Vue.use(VueExpandableImage);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
