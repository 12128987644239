export default {
  actions: {},
  mutations: {
    updateMaps(state, data) {
      state.maps = data;
    },
    updateLayerTarget(state, value) {
      state.layerTarget = value;
    },
    updateDisrtictTarget(state, value) {
      state.districtTarget = value;
    },
    updateFilteredDisctricts(state, data) {
      let arr = [];
      data.features.forEach(e =>
        arr.push({ id: e.properties.id, name: e.properties.name, value: null })
      );
      state.disctricts = arr;
      state.ids_districts = "";
    },
    setFilteredDistrict(state, { index, value }) {
      state.disctricts[index].value = value;
      state.ids_districts = state.disctricts
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredTypes(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.types = arr;
      state.ids_types = "";
    },
    setFilteredType(state, { index, value }) {
      state.types[index].value = value;
      state.ids_types = state.types
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredSanitary(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.sanitary = arr;
      state.ids_sanitary = "";
    },
    setFilteredSanitary(state, { index, value }) {
      state.sanitary[index].value = value;
      state.ids_sanitary = state.sanitary
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredStatuses(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.statuses = arr;
      state.ids_statuses = "";
    },
    setFilteredStatus(state, { index, value }) {
      state.statuses[index].value = value;
      state.ids_statuses = state.statuses
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredEvents(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.events = arr;
      state.ids_events = "";
    },
    setFilteredEvent(state, { index, value }) {
      state.events[index].value = value;
      state.ids_events = state.events
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredVermin(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.vermin = arr;
      state.ids_vermin = "";
    },
    setFilteredVermin(state, { index, value }) {
      state.vermin[index].value = value;
      state.ids_vermin = state.vermin
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },

    updateFilteredDiseases(state, data) {
      let arr = [];
      data.forEach(e => arr.push({ id: e.id, name: e.name, value: null }));
      state.diseases = arr;
      state.ids_diseases = "";
    },
    setFilteredDiseas(state, { index, value }) {
      state.diseases[index].value = value;
      state.ids_diseases = state.diseases
        .filter(el => el.value)
        .map(el => el.id)
        .join(",");
    },
    setFiltredRedbook(state, value) {
      state.redbookTarget = value;
    }
  },
  state: {
    map: null,
    maps: [
      {
        id: "osm",
        name: "Open Street Map",
        visible: true,
        alpha: 100
      },
      { id: "2gis", name: "2GIS", visible: false, alpha: 100 },
      {
        id: "bingMapsAerial",
        name: "Bing Maps Aerial",
        visible: false,
        alpha: 100
      },
      {
        id: "bingMapsAerialWithLabels",
        name: "Bing Maps Aerial with labels",
        visible: false,
        alpha: 100
      },
      {
        id: "mapbox",
        name: "MapBox Streets Satellite",
        visible: false,
        alpha: 100
      },
      { id: "arcgis", name: "ArcGis Спутник", visible: false, alpha: 100 }
    ],
    apiKeyBing:
      "AsBk2lRoIexJnw-YS3cdXLMvqkCUOfZ1SSlRAu48ASPwwSoN7hKfV8dby6rTA66r",
    layerTarget: 0,
    districtTarget: 0,
    disctricts: [],
    ids_districts: "",
    types: [],
    ids_types: "",
    sanitary: [],
    ids_sanitary: "",
    statuses: [],
    ids_statuses: "",
    events: [],
    ids_events: "",
    vermin: [],
    ids_vermin: "",
    diseases: [],
    ids_diseases: "",
    redbookTarget: false,
    mapChange: 0
  }
};
