import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import breeds from "./modules/breeds";
import flowers from "./modules/flowers";
import flowerGarden from "./modules/flowerGarden";
import vines from "./modules/vines";
import ageGroups from "./modules/ageGroups";
import categories from "./modules/categories";
import diseases from "./modules/diseases";
import events from "./modules/events";
import settings from "./modules/settings";
import statuses from "./modules/statuses";
import types from "./modules/types";
import vermins from "./modules/vermins";
import districts from "./modules/districts";
import users from "./modules/users";
import roles from "./modules/roles";
import plants from "./modules/plants";
import sanitary from "./modules/sanitary";
import layers from "./modules/layers";
import flowerCategory from "./modules/flowerCategory";
import selectedPlant from "./modules/plantView";
import taxatorgrid from "./modules/taxatorgrid";
import bushes from "./modules/bushes";
import clustergrid from "./modules/clustergrid";
import redBook from "./modules/redBook";
import appeal from "./modules/appeal";
import message from "./modules/message";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    breeds,
    ageGroups,
    categories,
    diseases,
    events,
    settings,
    statuses,
    types,
    vermins,
    flowers,
    flowerCategory,
    flowerGarden,
    vines,
    districts,
    users,
    roles,
    plants,
    sanitary,
    layers,
    selectedPlant,
    taxatorgrid,
    bushes,
    clustergrid,
    redBook,
    appeal,
    message
  }
});
